import React, { useContext, useEffect, useState,useRef } from 'react';
import './Main.css';
import LayoutComponent from './LayoutComponent';
import occurrenceId from '../control/occurrence';
import { UserContext } from '../App';
import konsole from '../network/konsole';
import Services from '../network/Services';
import commonLib from '../control/commonLib';
import { useHistory } from 'react-router-dom';
import { selfPlannerId } from '../control/Constant';
import Toaster from '../Toaster/Toaster';
import { EnquiryRegistrationId } from '../control/Constant';
import Payment from './SelfPlanner/VenuePayment/Payment';
import { AoLoginBaseUrl } from '../network/url';
import {leadGetApi,leadCommonApi,isNotValidNullUndefinedfile,capitalizeName} from '../control/Constant'


export default function ConfirmBooking(props) {
  const { setLoader } =  useContext(UserContext);
  const [clientIpAddress, setClientIpAddress] = useState('');
  const [showPayment, setshowPayment] = useState(false)
  const attendeeObj = commonLib.getObjFromStorage("attendeeObj");
  const seminarObj = commonLib.getObjFromStorage("seminarObj");
  const stage = commonLib.getObjFromStorage("stage");
  const DataState = commonLib.getObjFromStorage("DataState");
  const stateObj = commonLib.getObjFromStorage('stateObj');
  const isNotSlots = commonLib.getObjFromStorage("isNotSlots");
  const uniquelink = `${AoLoginBaseUrl}account/Signin?subtenantId=${seminarObj.subtenantId}`
  const stageLink = (stage == null || stage == undefined || stage == "" || stage == "PLANNER_CLASSROOM") ? DataState  : stage
  const selectedTimeSlot = commonLib.getObjFromStorage("selectedTimeSlot");
  const selectedSlot = commonLib.getObjFromStorage('timeSlot');
  const plannerCalId = commonLib.getObjFromStorage('plannerCalId');
  const classSeminarData = commonLib.getObjFromStorage("classRoomSeminar");
  const countRenderData = commonLib.getObjFromStorage("countRenderData");
  const planuser = commonLib.getObjFromStorage('classVenue');
  const venueObj2 = commonLib.getObjFromStorage('venueObj2');
  const OrderId = props.location.search;
  const paymentSuccesStatus = props?.location?.state || commonLib.getQueryParameters(OrderId, 'TrxnStatus')
  const orderId1 = commonLib.getQueryParameters(OrderId, 'OrderId')
  const orderId = commonLib.getQueryParameters(OrderId, 'OrderId')
  const classNewUserData = JSON.parse(commonLib.getSessionStoarge('newRegisterUserData'))
  const newRegisterUserDat = commonLib.getSessionStoarge('newRegisterUserDat')
  const bookingId = commonLib.getObjFromStorage("classbookingId");
  let subtenantId = commonLib.getObjFromStorage('seminarObj')?.seminarSubtenantId
  const planningData = commonLib.getObjFromStorage("planningData");
  const templeteId1 = useRef(false);
 
   
  let venuee = !isNotValidNullUndefinedfile(classSeminarData?.seminarVenue) ? "" : classSeminarData?.seminarVenue 
  let zipCode = !isNotValidNullUndefinedfile(classSeminarData?.venueZipCode) ? "" : classSeminarData?.venueZipCode
  let location = !isNotValidNullUndefinedfile(classSeminarData?.venueAddressLine1) ? "" : classSeminarData?.venueAddressLine1 
  const address = `${venuee}  ${location} ${zipCode}`



  useEffect(() => {
     if (paymentSuccesStatus === "SUCCESS") {
      if (stageLink === "CLASSROOM") {
        const bookingId1 = bookingId;
        const isActive = true;
        const updatedBy = attendeeObj?.seminarAttendeeUserId;
        const remarks = "Paymentstatus";
        const paymentTrxnNo = orderId1;
        const paymentStatusId = 1;
        const bookingStatusId = 1;
        paymentStatus(bookingId1, isActive, updatedBy, remarks, paymentTrxnNo, paymentStatusId, bookingStatusId);

      }else{
      const plannerAppId=0;
      const seminarAttendeeId=attendeeObj.seminarAttendeeId
      const paymentStatusId = 1;
      const paymentStatusDate = new Date();
      const upsertedBy = attendeeObj.seminarAttendeeUserId;
      const isActive = true;
      const planningId = planningData?.planningId
      if(countRenderData === true && stageLink == "PLANNER"){
      upsertPlannerBooking(plannerAppId, seminarAttendeeId, orderId, paymentStatusId, paymentStatusDate, upsertedBy, isActive,planningId);}
      commonLib.setDatatoSession("countRenderData", JSON.stringify(false))
      }
      }

    if (stageLink === "CLASSROOM" && paymentSuccesStatus === "SUCCESS") {
      if (newRegisterUserDat === "true") {
        handleWalkInCheckInUser(classNewUserData?.useData.seminarId, classNewUserData?.useData.seminarAttendeeId, classNewUserData?.useData.userId, 5, classNewUserData?.useData.seminarAttendeeId);
      } else {
        const userRegistration = {
          roleId: 2,
          noOfAttendee: 1,
          clientIPAddress: clientIpAddress,
          countryCode: '+1',
          semUserStatusId: EnquiryRegistrationId,
          phoneNumber: attendeeObj?.attendeeMobileNo,
          firstName: attendeeObj?.attendeeFullName,
          emailAddress: attendeeObj?.attendeeEmailAddress
        };
        registerNewUser(745, classSeminarData?.seminarId, userRegistration);
      }
    }
  }, []);
    
  const getUserRoles = (userId,data1,plannerData)=>{
    const promises = Services.GetUserRolebByID(userId);
    promises.then(res => {
       let len = res.data.data.length;
       let data = res.data.data;
       konsole.log('registered Checkin res11', data);
       let newData = []
       for(let i = 0 ; i <len;i++){
        
        newData.push(data[i].roleId)
       }
      const found = newData.find(element => element == 1 || element == 9 || element == 10 || element == 2);
     
      if (found == 1 || found == 9 || found == 10) {
        if (data[0]?.isActive === true && data[0]?.auth0Id != null) {
          let tempmId = templeteId1.current === true ? 15 : 13
          GetEmailTemplateUser(tempmId, true,"","","",data1,plannerData)

        }
        else {
          getActivateUser(data[0]?.loginUserId, attendeeObj?.seminarAttendeeUserId, data[0]?.signUpPlatform, attendeeObj?.seminarAttendeeUserId, clientIpAddress,plannerData)
        }
      } else {
        if (newData.length === 1 && found === 2) {
          let newUser = true
          getActivateUser(data[0]?.loginUserId, attendeeObj?.seminarAttendeeUserId, data[0]?.signUpPlatform, attendeeObj?.seminarAttendeeUserId, clientIpAddress, newUser,plannerData)

        }

      }  
    })
      .catch(err => {
        konsole.log("err", err.response);
      }).finally(fin => {
      })

  }

  const GetEmailTemplateUser = (TempalteId,isActive,elsePart,response,newUser,userData,plannerData)=>{
   
    const { plannerEmailId, bookingStatusName, plannerUserName, plannerMobileNo } = plannerData ?? {};
    const { emailAddress, mobileNo, fName, lName } = (userData?.attendees?.length ?? 0) > 0 ? userData.attendees[0] : {};
    const {memberName,primaryEmailId,primaryPhoneNumber} = response ?? {}
    const promises = Services.GetEmailTemplateUser(TempalteId,isActive);

   
    promises.then(res => {
      if(elsePart === true){
      let allData ={
        emailTo: primaryEmailId,
        emailMappingTablePKId: attendeeObj?.seminarAttendeeId.toString(),
        ATTENDEENAME: memberName,
        PlannerDate: templeteId1.current === true ? "" : commonLib.getFormattedDate(selectedTimeSlot?.plannerDate),
        PlannerTime: templeteId1.current === true ? "" :selectedTimeSlot?.plannerTime,
        UNIQUELINK: response?.activationURL,
        SUBTENANTNAME: venueObj2?.subtenantName,
        COORDINATORNAME: "",
        ATTENDEEEMAIL: primaryEmailId,
        ATTENDEECELLNO: primaryPhoneNumber,
        createdBy: stateObj?.userId,
        PlannerName: plannerUserName,
        bookingStatusName:bookingStatusName,
        plannerEmailId: plannerEmailId,
        plannerMobileNo: plannerMobileNo,
        PLANNINGNAME : planningData?.planningName
             
      }  
         if(isNotSlots == true){
          let seminarId = seminarObj?.seminarId
          sendLatterMail(seminarId, true, memberName, response?.activationURL, primaryEmailId, primaryPhoneNumber)
          occurrenceId.callOccurrenceIdFunc(14, subtenantId ,null, allData)

         }else{
          occurrenceId.callOccurrenceIdFunc(11, subtenantId, null , allData)
          occurrenceId.callOccurrenceIdFunc(12, subtenantId, null , allData) 
         }
        
      }
      else{
      
    let allData ={
      emailTo: emailAddress,
      emailMappingTablePKId: attendeeObj?.seminarAttendeeId.toString(),
      ATTENDEENAME: fName+" "+lName,
      PlannerDate: templeteId1.current === true ? "" : commonLib.getFormattedDate(selectedTimeSlot?.plannerDate),
      PlannerTime: templeteId1.current === true ? "" :selectedTimeSlot?.plannerTime,
      UNIQUELINK: newUser === true ? response?.activationURL : uniquelink,
      SUBTENANTNAME: venueObj2?.subtenantName,
      COORDINATORNAME : "",
      ATTENDEEEMAIL: "",
      ATTENDEECELLNO: "",
      createdBy: stateObj?.userId,
      PlannerName: plannerUserName,
      bookingStatusName: bookingStatusName,
      plannerEmailId: plannerEmailId      
      } 
      if(isNotSlots == true){
        let seminarId = seminarObj?.seminarId
        sendLatterMail(seminarId, true, memberName, response?.activationURL, primaryEmailId, primaryPhoneNumber)
        occurrenceId.callOccurrenceIdFunc(14,subtenantId,null,allData)

       }else{
        occurrenceId.callOccurrenceIdFunc(11, subtenantId, null , allData)
        occurrenceId.callOccurrenceIdFunc(12, subtenantId, null , allData)
       }
   
     }
    })
  
    .catch(err => {
      konsole.log("err", err.response);
      
    }).finally(fin => {
    })

  }
  const getActivateUser =(userRegstrtnId,userId,signUpPlatform,createdBy,clientIPAddress,newUser,plannerData)=>{
    const promises = Services.userActivationPost(userRegstrtnId,userId,signUpPlatform,createdBy,clientIPAddress);
    promises.then(res => {
      konsole.log("same321",res)
      let response1 = res.data.data
      let elsePart = true
      let tempmId =  templeteId1.current === true ? 15 : 13
      GetEmailTemplateUser(tempmId,true,elsePart,response1,newUser,"",plannerData)

    })
    .catch(err => {
    }).finally(fin => {
      konsole.log("finish");
      setLoader(false);
    })
  }
  

  const upsertPlannerBooking = (plannerAppId, seminarAttendeeId, billTrackNo, paymentStatusId, paymentStatusDate, upsertedBy, isActive,planningId) => {
    setLoader(true);
    const promises = Services.upsertPlannerBooking(plannerAppId, seminarAttendeeId, billTrackNo, paymentStatusId, paymentStatusDate, upsertedBy, isActive,planningId);
    promises.then((res) => {
      setLoader(false);
      const plannerAppId = res.data.data[0].plannerAppId;
      const seminarAttendeeId = res.data.data[0].seminarAttendeeId;

      const bookingStatusId = isNotSlots === true ? 6 : 1;
      const bookingStatusDate = new Date();
      const updatedBy = res.data.data[0].seminarAttendeeUserId

      updatePlannerBooking(plannerAppId, seminarAttendeeId, plannerCalId, bookingStatusId, bookingStatusDate, updatedBy);

    })
      .catch((error) => {
        konsole.log("error", error.response)
        commonLib.setDatatoSession("countRenderData", JSON.stringify(true))
      })
      .finally(error => {
        konsole.log("finish");
        setLoader(false);
      })
  }
  const updatePlannerBooking = (plannerAppId, seminarAttendeeId, plannerCalId, bookingStatusId, bookingStatusDate, updatedBy) => {
    setLoader(true);
    const promises = Services.updatePlannerBooking(plannerAppId, seminarAttendeeId, plannerCalId, bookingStatusId, bookingStatusDate, updatedBy);
    promises.then((res) => {
      setLoader(false);
      const timeSlot = selectedSlot
      const attendeeUserId = attendeeObj?.attendeeUserId;
      const plannerData = res.data.data
      handleWalkInCheckInUser(seminarObj?.subtenantId, seminarAttendeeId, attendeeUserId, selfPlannerId, updatedBy, timeSlot,plannerData);
    }).catch((err) => {
      konsole.log("error", err)
    })
      .finally(error => {
        konsole.log("finish");
        setLoader(false);
      })
  }
  const newTimeFormate = ()=>{
    const toTime = classSeminarData?.remarks.slice(11, 31);
    return `${commonLib.getFormattedTime(classSeminarData?.seminarTime)} To ${commonLib.getFormattedTime(toTime)}`
  }
  const getLoggedInUserdata = async (email,name,method,tag) => {
    let json = {email:email,tag:tag} 
      
    const result = await leadGetApi(json);
      if(result !== null && result !== undefined && result !== ""){
        const result2 = await leadCommonApi(result,method)
        return result2
      }
    if(result=='err') return;
};
  const runFunctionsSequentiallyClass = async (userRegistration) => {
  // console.log("ddddssssssssfee",userRegistration)
  // let tag1 = [`notbooked,${userRegistration?.firstName}`]
  let tag1 = [`notbooked`]
  // let tags = [`booked,AO Workshop Purchase,${userRegistration?.firstName}`]
  let tags = [`booked,AO Workshop Purchase`]
  const result1 = await getLoggedInUserdata(userRegistration?.emailAddress, `${userRegistration?.firstName}`, "DELETE",tag1);
  const result2 = await getLoggedInUserdata(userRegistration?.emailAddress, `${userRegistration?.firstName}`, "POST",tags);

}




  const classbookingNew = (bookingId, isActive, updatedBy, remarks, classroomAttendeeId, userRegistration) => {
    const promise = Services.putClassBooking(bookingId, isActive, updatedBy, remarks, classroomAttendeeId)
    promise.then((res) => {
      runFunctionsSequentiallyClass(userRegistration)
    })
      .catch(err => {
      }).finally(fin => {
      })

  }
  const GetTextTemplateUsers1 = (TempalteId,isActive,unique)=>{
    
      Services.getTextTemplateFunc(TempalteId,isActive).then(res => {
      let data = res.data.data[0]?.textTemplateContent
      let data2 = res.data.data[0]
      let createdBy = attendeeObj?.seminarAttendeeUserId;
      const replacedStr1 = data.
        replace('@@SEMINARNAME', seminarObj?.seminarTopic).
        replace('@@SUBTENANTNAME', venueObj2?.subtenantName).
        replace('@@ATTENDEENAME', venueObj2?.attendeeFullName).
        replace('@@CLASSROOMVENUE', address).
        replace('@@CLASSROOMNAME', classSeminarData?.seminarTopic).
        replace('@@CLASSROOMDATE', newDateFormate()).
        replace('@@CLASSROOM TIMINGS', newTimeFormate());

      Services.postSendText(data2?.textTemplateType, venueObj2?.attendeeMobileNo,replacedStr1, data2?.textTemplateId, 1, "tblSeminarAttendeeList", venueObj2?.seminarAttendeeId, createdBy).then((response) => {
        }).catch((error) => {
        konsole.log("sendTextAPIFuncError", error)
      }).finally(() => {
        konsole.log("sendTextAPIFuncEnd")
      })
    }).catch(err => {
      konsole.log("err", err.response);
    }).finally(fin => {
    })

  }
  const handleWalkInCheckInUser = (seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy, timeSlot,plannerData) => {
    setLoader(true);
    const promises = Services.updateSeminarAttendeeInfo(seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy);
    promises.then(res => {
      setLoader(false);
   
      if(stageLink !== "CLASSROOM"){
        if(isNotSlots !== true){
          runFunctionsSequentiallyPlanner(res.data.data)
        }
       
        getUserRoles(attendeeObj?.seminarAttendeeUserId, res.data?.data,plannerData)
       }
     
    })
      .catch(err => {
        konsole.log("err", err.response);
        Toaster.error(err.response.data.messages[0])
      }).finally(fin => {
        konsole.log("finish");
        setLoader(false);
      })
  }
  const runFunctionsSequentiallyPlanner = async (data) => {
    // console.log("attendees",data?.attendees[0]?.emailAddress)
    // let tag1 = [`notbooked,${data?.attendees[0]?.fName} ${data?.attendees[0]?.lName}`]
    let tag1 = [`notbooked`]
    // let tags = [`booked,Initial Consultation,${data?.attendees[0]?.fName} ${data?.attendees[0]?.lName}`]
    let tags = [`booked initial`]
    const result1 = await getLoggedInUserdata(data?.attendees[0]?.emailAddress,`${data?.attendees[0]?.fName} ${data?.attendees[0]?.lName}`, "DELETE",tag1);
    const result2 = await getLoggedInUserdata(data?.attendees[0]?.emailAddress,`${data?.attendees[0]?.fName} ${data?.attendees[0]?.lName}`, "POST",tags);
  };


  const getIpAddress = () => {
    let promise = Services.getIpAddress();
    promise.then(res => {
      konsole.log("IP address78", res);
      setClientIpAddress(res.IPv4);
    })
  }
  const data1 = (seminar11, toDate) => {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var d = new Date(seminar11);
    var dayName = days[d.getDay()];
    var d2 = new Date(toDate);
    var dayName2 = days[d2.getDay()];
    return "(" + dayName + "-" + dayName2 + ")"
}
  const newDateFormate = ()=>{
    const toDate = classSeminarData?.remarks.slice(0, 11);
    return `${commonLib.getFormattedDate(classSeminarData?.seminarDate)} To  ${commonLib.getFormattedDate(toDate)} 
   ${data1(classSeminarData?.seminarDate, toDate)}
   
    ` 

}
  const GetEmailTemplateUsers = (TempalteId,isActive,)=>{
   Services.GetEmailTemplateUser(TempalteId,isActive).then(res => {
      let data = res.data.data[0]?.templateContent
      let data2 = res.data.data[0]
       const replacedStr1 = data.
      replace('@@ATTENDEENAME',venueObj2?.attendeeFullName).
      replace('@@SUBTENANTNAME',venueObj2?.subtenantName).
      replace('@@CLASSROOMNAME',classSeminarData?.seminarTopic).
      replace('@@CLASSROOMVENUE',address).
      replace('@@CLASSROOMDATE',newDateFormate()).
      replace('@@CLASSROOM TIMINGS',newTimeFormate());

     let emailType = data2?.templateType;
     let emailTo = venueObj2?.attendeeEmailAddress;
     let emailSubject = data2?.emailSubject;
     let emailTemplateId =  data2?.templateId;
     let emailContent = replacedStr1;
     let emailStatusId =  1;
     let emailMappingTable = "tblSeminarAttendeeList";
     let emailMappingTablePKId = venueObj2?.seminarAttendeeId;
     let createdBy =  attendeeObj?.seminarAttendeeUserId;
     SendEmailData(emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy)
    }).catch(err => {
    }).finally(fin => {
    })

  }
  const handelSend =(occurrenceId, isActive, subtenantId,unique)=>{
    setLoader(true)
      const promise = Services.postgetCommMedium(occurrenceId, isActive, subtenantId);
      promise.then((res) => {
          setLoader(false)
          let textId = res.data.data[0]?.applicableTextTempId;
          let applicableEmailTempId = res.data.data[0]?.applicableEmailTempId
          if(applicableEmailTempId !== null && applicableEmailTempId !== undefined && applicableEmailTempId !== ""){
              GetEmailTemplateUsers(applicableEmailTempId,true,unique)
          }
          
      if(textId !== null && textId !== undefined && textId !== ""){          
              GetTextTemplateUsers1(textId,true,unique)
      }
    
        
       
    
    
    }).catch((err) => {
  }).finally(error => {
    konsole.log("finish");
    setLoader(false);
  })

  }
  const SendEmailData = (emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy)=>{
       const promises = Services.SendEmailCom(emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy);
       promises.then(res => {  })
       .catch(err => {
         konsole.log("err", err.response);
         
       }).finally(fin => {
       })
  }

  const registerNewUser = (subtenantId1, seminarId, userRegistration,selectedUserObj) => {
        const promise = Services.registerNewUser(subtenantId1, seminarId, userRegistration)

      promise.then((res) => {
        classbookingNew(bookingId,true,attendeeObj?.seminarAttendeeUserId,"classBooking",res.data.data?.seminarAttendeeId,userRegistration)
        handelSend(18, true, venueObj2?.seminarSubtenantId)
        userActivateIP(res.data.data?.seminarAttendeeId)
        handleWalkInCheckInUser(attendeeObj?.seminarId,attendeeObj?.seminarAttendeeId,attendeeObj?.seminarAttendeeUserId,10,attendeeObj?.seminarAttendeeUserId)

    })
      .catch(err => {
        alert(err.response.data.messages[0])      
      }).finally(fin => {
      })
  }


const userActivateIP = (seminarAttendeeId)=>{
     const promises = Services.putUserActivate1(seminarAttendeeId);
      promises.then(res => {}).catch(err => {
      }).finally(fin => {
      })
}

const paymentStatus =(bookingId,isActive,updatedBy,remarks,paymentTrxnNo,paymentStatusId,bookingStatusId)=>{

  const promise = Services.putPaymentClassBookingStatus(bookingId,isActive,updatedBy,remarks,paymentTrxnNo,paymentStatusId,bookingStatusId)
  konsole.log("rrgis11",bookingId,isActive,updatedBy,remarks,paymentTrxnNo,paymentStatusId,bookingStatusId)
    promise.then((res) => {
      konsole.log("resti11",res)
})
    .catch(err => {
      konsole.log("errfd11", err);
      
    }).finally(fin => {
    })
}
const sendLatterMail = (seminarId,isActive,memberName,activationURL,primaryEmailId,primaryPhoneNumber) =>{
  let promise = Services.getSeminarPublisher1(seminarId, isActive)
  promise.then((res) => {
    let coordinatorData = res.data.data[0].coordinators
    for (let i = 0; i < coordinatorData.length; i++) {
     
      let allData1 = {
        ATTENDEENAME: isNotValidNullUndefinedfile(memberName) && capitalizeName(memberName),
        emailTo: coordinatorData[i].coordinatorEmailId,
        UNIQUELINK: activationURL,
        ATTENDEEEMAIL: primaryEmailId,
        ATTENDEECELLNO: primaryPhoneNumber,
        COORDINATORNAME: coordinatorData[i].coordinatorFullName,
        coordinatorEmailId: coordinatorData[i].coordinatorEmailId,
        COORDINATORNUMBER: coordinatorData[i].coordinatorMobileNo,
        SUBTENANTNAME: venueObj2?.subtenantName,


      }     
      occurrenceId.callOccurrenceIdFunc(15, subtenantId ,null, allData1)
      }





  }).catch((error) => {
    konsole.log("errorData", error)
  }).finally(() => {
    konsole.log("end")
  }) 
  



}




  const tryAgain =()=>{
    if(stageLink == "CLASSROOM" ){
      setshowPayment(true)     

    }
    else{
      setshowPayment(true)
    }
  }

  return (
    <div>
      <LayoutComponent>
        <div>

          {((paymentSuccesStatus == "SUCCESS") && (stageLink == "CLASSROOM" || stageLink == "PLANNER")) ?

            <div className='container-fluid pb-3 confirmationBooking-main'>
              <div className='row mt-3'>
                <div className='col-sm-6'>
                  <h4 >{
                    stageLink === "PLANNER" ? (<div>Appointment Booking :<b> Book an Appointment</b></div>) :
                      (<div>Workshop Registration: <b>Registration Confirmation</b></div>)
                  }</h4>
                  {stageLink === "PLANNER" ? <p className='fs-5'>Topic : {seminarObj.seminarTopic}</p> : ""}
                </div>
                {isNotSlots !== true &&
                <div className='col-sm-6 text-end'>
                  <p><span style={{ color: '#820024' }}>Seleted Date :</span> {stageLink == "CLASSROOM" ? commonLib.getFormattedDate(classSeminarData?.seminarDate) : selectedTimeSlot?.plannerDate}</p>
                  <p><span style={{ color: '#820024' }}>Seleted Time Slot :</span> {stageLink == "CLASSROOM" ? commonLib.getFormattedTime(classSeminarData?.seminarTime) : selectedTimeSlot.plannerTime} </p>
                </div>}
              </div>
              <div className='row'>
                <div className='mt-5 m-auto text-center bg-white py-5 col-12 col-sm-8'>
                  <h1 className='congratulationNew fs-1 mt-3'>Congratulations</h1>
                  <h3 className='fs-4 '>
                    {stageLink == "CLASSROOM" ? "You have Successfully Registered for Workshop"
                     :  isNotSlots == true ? "Your request has been sent to the event coordinator.  Our team will reach out to you at the earliest possible opportunity to set an appointment with the planner." : "You have Successfully Booked your Appointment"}</h3>
                  <p className='fs-5'>Details has been sent to <span className='fw-bold'>{attendeeObj.attendeeEmailAddress}</span></p>

                </div>
              </div>
            </div>

            : <>

              <div className='container-fluid pb-3 confirmationBooking-main' >
                <div className='row'>
                  <div className='col-sm-6 '>
                    <p className='mt-2 ms-2 fs-4 fw-700'>
                      {
                        stageLink == "CLASSROOM" ? (<div>Workshop Registration: <b>Payment Status</b></div>) :
                          (<div>Appointment Booking :<b>Payment Status</b></div>)
                      }
                    </p>
                  </div>
                </div>

                <div className='container bg-white mb-5'>
                  <div className='text-center '>
                    <img className='mt-5' src='\imgpsh_fullsize_anim.png'></img>
                  </div>
                  <h1 className=' congratulationNew text-center  mt-2 fw-bold fs-1 pt-3 ' style={{ color: "#F2494B" }}>Your Payment is Failed</h1>



                  <div className='btm text-center pb-4 mb-5'>
                    <button type='text' className='cnt-bttn border-0 rounded text-center p-2 mt-2 mb-5' onClick={tryAgain}>
                      Try Again
                    </button>
                  </div>

                </div>
              </div>

            </>}
          {
            showPayment ? <Payment
              attendeeObj={attendeeObj}
              classData={stageLink == "CLASSROOM" ? planuser : venueObj2} /> : ''
          }
        </div>
      </LayoutComponent>
    </div>
  )
}
